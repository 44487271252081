import { api, param2query } from "./api.service";

export const ATRIBUICAO = {
  MEDICO: 1,
  ENFERMEIRO: 2,
  ADMINISTRATIVO: 3,
}

export const getAll = (params) => {
  return api.get('atribuicao/listar?' + param2query(params));
};

export default { getAll };
